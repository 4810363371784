<template>
    <br>
    <!-- <a-typography-text>T.B.D. RangePicker表示するとエラーになる</a-typography-text> -->
    <!-- <a-range-picker /> -->
    <a-form autocomplete="off">
        <a-form-item label="開始月" name="start_date" :rules="[{ validator: checkStartMonth, trigger: 'change' }]">
            <a-input v-model:value="start_date" allowClear=true placeholder="YYYYMM" style="width: 120px"/>
        </a-form-item>
        <a-form-item label="終了月" name="end_date" :rules="[{ validator: checkEndMonth, trigger: 'change' }]">
            <a-input v-model:value="end_date" allowClear=true placeholder="YYYYMM" style="width: 120px"/>
        </a-form-item>
    </a-form>
</template>

<script>
import { defineComponent, inject } from 'vue';
// import moment from 'moment';

export default defineComponent({
    setup() {
        // 上位コンポーネントから共有された変数
        const start_date = inject('start_date')
        const end_date = inject('end_date')

        // 日付書式チェック
        let checkStartMonth = async (_rule, value) => {
            if (value) {
                // NOP、ダミーチェック
                // valueが常にundefinedとなるが、関数内で使用しない場合にLintでエラーとなるため
            }

            // YYYYMMを許可する
            var strDate = start_date.value
            if (!strDate.match(/^\d{6}$/)) {
                return Promise.reject('');
            }

            return Promise.resolve();
        };

        let checkEndMonth = async (_rule, value) => {
            if (value) {
                // NOP、ダミーチェック
                // valueが常にundefinedとなるが、関数内で使用しない場合にLintでエラーとなるため
            }

            // YYYYMMを許可する
            var strDate = end_date.value
            if (!strDate.match(/^\d{6}$/)) {
                return Promise.reject('');
            }

            return Promise.resolve();
        };

        return {
            start_date,
            end_date,

            checkStartMonth,
            checkEndMonth,
        };
    },
    methods:
    {
        clear() {
            this.start_date = ''
            this.end_date = ''
        },
    },
},
);
</script>