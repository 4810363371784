import axios from 'axios'

async function useUpdateRecord(id, start_date, customer, model, purpose, uploader, old_path, s3_path, id_token) {

    // console.log('useUpdateRecord:', id, ',', start_date, ',', model, ',', purpose, ',', uploader, ',', old_path)

    if (s3_path) {
        var splited = ''
        if (s3_path.includes('IncompanySensingCoreDataset')) {
            s3_path = "01_IncompanySensingCoreDataset/" 
                    + start_date + "_" 
                    + customer + "_" 
                    + model + "_" 
                    + purpose 
        }
        else if (s3_path.includes('IncompanyApplicationDataset')) {
            splited = s3_path.split('/')
            s3_path = splited[0] + "/"
                    + splited[1] + "/"
                    + start_date + "_" 
                    + customer + "_" 
                    + model + "_" 
                    + purpose 
        }
        else if (s3_path.includes('DatasetForCustomer')) {
            splited = s3_path.split('/')
            s3_path = splited[0] + "/"
                    + splited[1] + "/"
                    + start_date + "_" 
                    + customer + "_" 
                    + model + "_" 
                    + purpose 
        }
        else {
          // NOP
        }
      }

    var url = 'https://fy110hiw9b.execute-api.ap-northeast-1.amazonaws.com/dev/'
        + 'items?'
        + 'id=' + id + '&'
        + 'start_date=' + start_date + '&'
        + 'model=' + model + '&'
        + 'purpose=' + purpose + '&'
        + 'uploader=' + uploader + '&'
        + 'old_path=' + old_path + '&'
        + 's3_path=' + s3_path

    var result = ''
    await axios.get(url, {headers : {Authorization : id_token}})
        .then(response => {
            console.log(response)
            result = response.data;
        }).catch(e => {
            alert(e)
            console.log(e)
        });
        
    return result;
}

export default useUpdateRecord;