<template>
    <a-cascader :options="options" placeholder="Please select" @change="onChange" style="width: 500px" v-model:value="selected_value"/>
</template>

<script>
import { defineComponent, ref, inject } from 'vue';

// アップロード先のS3のフォルダ構成
// ローカル環境では何らかの条件(ラベルの文字列長？)でResizeObserver loop limit exceededが発生するが、
// Amplifyでのデプロイ後は発生しないため様子見
const options = [
    {
        value: '01_IncompanySensingCoreDataset', label: '01_IncompanySensingCoreDataset',
    },
    {
        value: '02_IncompanyApplicationDataset', label: '02_IncompanyApplicationDataset',
        children: [
            { value: '01_LookingAside', label: '01_脇見検知' },
            { value: '02_Drowsy_EyeClose', label: '02_居眠り検知' },
            { value: '03_Drowsiness', label: '03_眠気検知' },
            { value: '04_FaceRecognition', label: '04_個人認識' },
            { value: '05_Spoofing', label: '05_なりすまし検知' },
            { value: '06_Gesture', label: '06_ジェスチャ検知' },
            { value: '07_ChildAbandonment', label: '07_幼児置き去り検知' },
            { value: '08_Alcohol', label: '08_飲酒検知' },
            { value: '09_Call_Smoking', label: '09_通話・喫煙検知' },
            { value: '10_InattentiveDriving', label: '10_漫然運転検知' },
            { value: '11_AbnormalPosture', label: '11_姿勢崩れ' },
            { value: '12_Immobility', label: '12_不動検知' },
            { value: '13_Alertness', label: '13_覚醒度' },
            { value: '14_Hands-on', label: '14_ハンズオン検知' },
            { value: '15_Physique', label: '15_体格検知' },
            { value: '16_Pulse', label: '16_脈拍検知' },
            { value: '17_SeatBelt', label: '17_シートベルト検知' },
            { value: '18_FacialExpression', label: '18_表情認識' },
            { value: '19_MicroSleep', label: '19_マイクロスリーブ' },
            { value: '20_Reserved', label: '20_未使用' },
            { value: '99_Other', label: '99_Other' },
        ],
    },
    {
        value: '03_DatasetForCustomer', label: '03_DatasetForCustomer',
        children: [
            { value: '01_HONDA', label: '01_HONDA' },
            { value: '02_SUBARU', label: '02_SUBARU' },
            { value: '03_MAZDA', label: '03_MAZDA' },
            { value: '04_NISSAN', label: '04_NISSAN' },
            { value: '05_MMC', label: '05_MMC' },
            { value: '06_SUZUKI', label: '06_SUZUKI' },
            { value: '07_DAIHATSU', label: '07_DAIHATSU' },
            { value: '08_CommercialVehicle', label: '08_商用車' },
            { value: '09_Agriculture-BuildMachine', label: '09_農建機' },
            { value: '10_Reserved', label: '10_未使用' },
            { value: '11_Reserved', label: '11_未使用' },
            { value: '12_Reserved', label: '12_未使用' },
            { value: '13_Reserved', label: '13_未使用' },
            { value: '14_Reserved', label: '14_未使用' },
            { value: '15_Reserved', label: '15_未使用' },
            { value: '99_Other', label: '99_Other' },
        ],
    },
];

export default defineComponent({
    methods: {
        onChange(value) {
            if (value) {
                this.dataset_to = value.join("/")
            }
            else {
                this.dataset_to = ''
            }
        },
        clear() {
            this.selected_value = '';
            this.dataset_to = '';
        }
    },
    setup() {
        // 上位コンポーネントから共有された変数
        const dataset_to = inject('dataset_to')
        return {
            value: ref([]),
            options,
            dataset_to,
        };
    },
    data() {
        return {
            selected_value: []
        };
    },
},
);

</script>