<template>
  <div />
<!-- 再描画されないため無効化 -->
<!-- <router-link to="/searchdataset" custom v-slot="{ navigate }">
      <a-button type="link" @click="navigate" role="link" size="large">
        検索
      </a-button>
    </router-link> -->
</template>

<script>
</script>