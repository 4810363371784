<template>
  <div>
    <SearchDatasetView/>
  </div>
</template>

<script>
import SearchDatasetView from '@/components/views/searchdataset/SearchDatasetView.vue';

export default {
  components: {
    SearchDatasetView,
  },
}
</script>