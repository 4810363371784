<template>
  <!-- メタデータ入力フォーム表示ボタン -->
  <InputMetadataDrawer />

  <!-- 検索ボタン -->
  <router-link to="/searchdataset" custom v-slot="{ navigate }">
    <a-button type="primary" @click="navigate" role="link">
      検索
    </a-button>
  </router-link>

  <!-- 認可状態切替ボタン(デバッグ用) -->
  <!-- <a-space>
    <div v-if="is_authorized">
      <a-button danger @click="switchAuthrorization">(デバッグ用)編集/削除を禁止</a-button>
    </div>
    <div v-else>
      <a-button type="primary" danger @click="switchAuthrorization">(デバッグ用)編集/削除を許可</a-button>
    </div>
  </a-space> -->
</template>

<script>
import InputMetadataDrawer from '@/components/templates/inputmetadata/InputMetadataDrawer.vue';
import { inject } from 'vue';
import { Auth } from 'aws-amplify';

export default {
  components: {
    InputMetadataDrawer,
  },
  setup() {

    // 上位コンポーネントから共有された変数
    const is_authorized = inject('is_authorized')
    const username = inject('username')
    const id_token = inject('id_token')

    return {
      is_authorized,
      username,
      id_token,
    };
  },
  methods: {
    // switchAuthrorization() {
    //   this.is_authorized = !this.is_authorized;
    // },
    checkAuthorizedUser() {
      // Cognitoよりユーザー情報を取得し、「編集権限あり」グループに属していれば編集を許可する
      if (!this.username) {
        Auth.currentAuthenticatedUser({
          bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            this.username = user.username

            const { signInUserSession } = user;
            this.id_token = signInUserSession.idToken.jwtToken
            console.log("idToken", this.id_token);

            var groups = signInUserSession["accessToken"]["payload"]["cognito:groups"];
            // console.log("cognitoGroup",groups)
            this.is_authorized = groups.includes("編集権限あり")

          })
          .catch((err) => console.log(err));
      }
    },

  },
  created() {
    // 画面生成時にユーザー権限をチェックする
    this.checkAuthorizedUser();
  },

}


</script>