import jszip from 'jszip'
import { saveAs } from 'file-saver';

async function useAsyncZipSaver(files, contents) {

    // console.log('useAsyncZipSaver:', fullpath)

    // zip圧縮(無圧縮)する
    let zip = new jszip();
    var idx = 0;
    files.forEach(file => {
        // console.log('useAsyncZipSaver:', file.key)

        // fullpathにフォルダ構造も含まれているためフォルダ情報は指定不要
        zip.file(file.key, contents[idx]);
        idx++;
    });

    // フォルダ名を取り出す
    var folder = files[0].key.split("/").reverse().slice(1).reverse().join("/");

    // ファイル保存する
    await zip.generateAsync({ type: 'blob' })
        .then((blob) => {
            saveAs(blob, folder + '.zip')
        })

}

export default useAsyncZipSaver;