import axios from 'axios'

async function useDeleteRecord(id, id_token) {

  var url = 'https://8twmn5521b.execute-api.ap-northeast-1.amazonaws.com/dev/items?id=' + id

  await axios.get(url, { headers: { Authorization: id_token } })
    .then(response => {
      console.log(response)
      if (response?.response?.status === 401) {
        throw new Error(response);
      }
    });

  return {
    useDeleteRecord,
  };
}

export default useDeleteRecord;