<template>
  <div class="right">
    <router-link to="/" custom v-slot="{ navigate }">
      <a-button type="link" @click="navigate" role="link" size="large">
        キャンセル
      </a-button>
    </router-link>

    <a-button type="primary" @click="onClick">検索</a-button>
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit('search-clicked');
    }
  }
}
</script>

<style scoped>
/* ボタンを右寄せで表示する */
.right {
  text-align: right;
}
</style>