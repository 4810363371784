<template>
  <authenticator :login-mechanisms="['email']" :hideSignUp=true>
    <template v-slot="{ user }">
      <div class="right">
        <a-space>
          <a-typography-text strong> {{ user.username }} </a-typography-text>
          <button @click="handleSignOut">Sign out</button>
        </a-space>
      </div>
      <div id="app">
        <router-view :username="user.username" />
      </div>
    </template>
  </authenticator>
</template>

<script>
import { provide, ref } from 'vue';
import { Auth } from 'aws-amplify'

export default {
  setup() {
    // 下位コンポーネントとの共有用変数
    const is_authorized = ref(null)
    const username = ref(null)
    const id_token = ref(null)

    provide('is_authorized', is_authorized)
    provide('username', username)
    provide('id_token', id_token)

    const handleSignOut  = () => {
        Auth.signOut()
        is_authorized.value = false
        username.value = ''
    }

    return {
      is_authorized,
      username,
      id_token,
      handleSignOut,
    }
  },

}
</script>

<style scoped>
/* ボタンを右寄せで表示する */
.right {
  text-align: right;
}
</style>