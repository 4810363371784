import * as vueRouter from 'vue-router';
import SearchDataset from '@/components/pages/SearchDatasetPage.vue';
import ViewDataset from '@/components/pages/ViewDatasetPage.vue';

const routes = [
  {
    path: '/',
    component: ViewDataset,
    props: true,
  },
  {
    path: '/searchdataset',
    component: SearchDataset,
    props: true,
  },
];

const router = vueRouter.createRouter({
  history: vueRouter.createWebHistory(),
  routes,
});

export default router;