<template>
  <a-menu id="dddddd" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys"  style="width: 300px" mode="inline" @click="handleClick">
    <template #icon>
      <AppstoreOutlined />
    </template>
    <a-menu-item key="01_IncompanySensingCoreDataset">01_IncompanySensingCoreDataset</a-menu-item>
    <a-sub-menu key="02_IncompanyApplicationDataset" title="02_IncompanyApplicationDataset">
      <a-menu-item key="LookingAside">01_脇見検知</a-menu-item>
      <a-menu-item key="Drowsy_EyeClose">02_居眠り検知</a-menu-item>
      <a-menu-item key="Drowsiness">03_眠気検知</a-menu-item>
      <a-menu-item key="FaceRecognition">04_個人認識</a-menu-item>
      <a-menu-item key="Spoofing">05_なりすまし検知</a-menu-item>
      <a-menu-item key="Gesture">06_ジェスチャ検知</a-menu-item>
      <a-menu-item key="ChildAbandonment">07_幼児置き去り検知</a-menu-item>
      <a-menu-item key="Alcohol">08_飲酒検知</a-menu-item>
      <a-menu-item key="Call_Smoking">09_通話・喫煙検知</a-menu-item>
      <a-menu-item key="InattentiveDriving">10_漫然運転検知</a-menu-item>
      <a-menu-item key="AbnormalPosture">11_姿勢崩れ</a-menu-item>
      <a-menu-item key="Immobility">12_不動検知</a-menu-item>
      <a-menu-item key="Alertness">13_覚醒度</a-menu-item>
      <a-menu-item key="Hands-on">14_ハンズオン検知</a-menu-item>
      <a-menu-item key="Physique">15_体格検知</a-menu-item>
      <a-menu-item key="Pulse">16_脈拍検知</a-menu-item>
      <a-menu-item key="SeatBelt">17_シートベルト検知</a-menu-item>
      <a-menu-item key="FacialExpression">18_表情認識</a-menu-item>
      <a-menu-item key="MicroSleep">19_マイクロスリーブ</a-menu-item>
      <a-menu-item key="Reserved">20_未使用</a-menu-item>
      <a-menu-item key="Other">99_Other</a-menu-item>
    </a-sub-menu>
    <a-sub-menu key="03_DatasetForCustomer" title="03_DatasetForCustomer">
      <a-menu-item key="HONDA">01_HONDA</a-menu-item>
      <a-menu-item key="SUBARU">02_SUBARU</a-menu-item>
      <a-menu-item key="MAZDA">03_MAZDA</a-menu-item>
      <a-menu-item key="NISSAN">04_NISSAN</a-menu-item>
      <a-menu-item key="MMC">05_MMC</a-menu-item>
      <a-menu-item key="SUZUKI">06_SUZUKI</a-menu-item>
      <a-menu-item key="DAIHATSU">07_DAIHATSU</a-menu-item>
      <a-menu-item key="CommercialVehicle">08_商用車</a-menu-item>
      <a-menu-item key="Agriculture-BuildMachine">09_農建機</a-menu-item>
      <a-menu-item key="Reserved">10_Reserved</a-menu-item>
      <a-menu-item key="Reserved">11_Reserved</a-menu-item>
      <a-menu-item key="Reserved">12_Reserved</a-menu-item>
      <a-menu-item key="Reserved">13_Reserved</a-menu-item>
      <a-menu-item key="Reserved">14_Reserved</a-menu-item>
      <a-menu-item key="Reserved">15_Reserved</a-menu-item>
      <a-menu-item key="Other">99_Other</a-menu-item>

    </a-sub-menu>
  </a-menu>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { AppstoreOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  setup() {
    const selectedKeys = ref(['1']);
    const openKeys = ref(['sub1']);
    // const titleClick = e => {
    //   console.log('titleClick', e);
    // };
    watch(
      () => openKeys,
      val => {
        console.log('openKeys', val);
      },
    );
    return {
      selectedKeys,
      openKeys,
      // handleClick,
      // titleClick,
    };
  },
  components: {
    AppstoreOutlined,
  },
  methods: {
    handleClick(e) {
      // console.log('click', e);
      // 親コンポーネントにイベント通知
      if (e.keyPath) {
        if (e.keyPath[0].includes('IncompanySensingCoreDataset')) {
          this.$emit('menu-changed', 'customer', 'Common')
        }
        else if (e.keyPath[0].includes('IncompanyApplicationDataset')) {
          this.$emit('menu-changed', 'purpose', e.keyPath[1])
        }
        else if (e.keyPath[0].includes('DatasetForCustomer')) {
          this.$emit('menu-changed', 'customer', e.keyPath[1])
        }
        else {
          // NOP
        }
      }
    },

  },

});
</script>