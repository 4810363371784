import { Storage } from 'aws-amplify';

async function useUploadDataset(upload_to, title, files,) {

    return Promise.all(
        Array.from(files).map(
            (file) =>
                new Promise((resolve, reject) => {
                    let filePath = upload_to + "/" + title + "/" + file.name;

                    console.log('useUploadDataset:', filePath)

                    Storage.put(filePath, file,
                        {
                            progressCallback(progress) {
                                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                            },
                        }
                    )
                        .then(result => {
                            console.log(result);
                            resolve(result);
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err);
                        });

                })
        )
    );

}

export default useUploadDataset;