<template>
    <div>
        <a-radio-group v-model:value="purpose" button-style="solid" @change="onChange">
            <a-radio-button value="LookingAside">脇見検知</a-radio-button>
            <a-radio-button value="Drowsy_EyeClose">居眠り検知</a-radio-button>
            <a-radio-button value="Drowsiness">眠気検知</a-radio-button>
            <a-radio-button value="FaceRecognition">個人認識</a-radio-button>
            <a-radio-button value="Spoofing">なりすまし検知</a-radio-button>
            <a-radio-button value="Gesture">ジェスチャ検知</a-radio-button>
            <a-radio-button value="ChildAbandonment">幼児置き去り検知</a-radio-button>
            <a-radio-button value="Alcohol">飲酒検知</a-radio-button>
            <a-radio-button value="Call_Smoking">通話・喫煙検知</a-radio-button>
            <a-radio-button value="InattentiveDriving">漫然運転検知</a-radio-button>
            <a-radio-button value="AbnormalPosture">姿勢崩れ</a-radio-button>
            <a-radio-button value="Immobility">不動検知</a-radio-button>
            <a-radio-button value="Alertness">覚醒度</a-radio-button>
            <a-radio-button value="Hands-on">ハンズオン検知</a-radio-button>
            <a-radio-button value="Physique">体格検知</a-radio-button>
            <a-radio-button value="Pulse">脈拍検知</a-radio-button>
            <a-radio-button value="SeatBelt">シートベルト検知</a-radio-button>
            <a-radio-button value="FacialExpression">表情認識</a-radio-button>
            <a-radio-button value="MicroSleep">マイクロスリーブ</a-radio-button>
            <a-radio-button value="AoI">AoI検知</a-radio-button>
            <a-radio-button value="Pre-study">事前検討</a-radio-button>
            <a-radio-button value="Over-detection_DrivingDataset">走行時過検出評価</a-radio-button>
            <a-radio-button value="BasicPerformanceDataset">基本性能評価</a-radio-button>
            <a-radio-button value="RobustnessDataset">ロバスト性評価</a-radio-button>
            <a-radio-button value="PerformanceDataset">性能評価データセット</a-radio-button>
            <a-radio-button value="NcapDataset">NCAP評価データセット</a-radio-button>
            <a-radio-button value="GSRDataset">GSR評価データセット</a-radio-button>
            <a-radio-button value="FleetData">Fleet評価</a-radio-button>
            <a-radio-button value="Other">Other</a-radio-button>
        </a-radio-group>
        <div v-if="isOther">
            <a-input v-model:value="purpose" allowClear=true placeholder="自由入力" />
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from 'vue';
export default defineComponent({
    setup() {
        // 上位コンポーネントから共有された変数
        const purpose = inject('purpose')
        return {
            purpose,
        };
    },
    data() {
        return {
            // 「Other」を選択しているか
            isOther: false,
        }
    },
    methods: {
        onChange(event) {
            var text = event.target.value;
            if (text.match('Other')) {
                this.purpose = '';
                this.isOther = true;
            }
            else {
                // this.purpose = value;
                this.isOther = false;
            }

        },
        clear() {
            this.purpose = '';
            this.isOther = false;
        }
    },
});

</script>