<template>
  <div>
    <ViewDatasetView />
  </div>
</template>

<script>
import ViewDatasetView from '@/components/views/viewdataset/ViewDatasetView.vue';

export default {
  components: {
    ViewDatasetView,
  },
}

</script>