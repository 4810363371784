<template>
  <div>
    <SearchDatasetHeader />
    <br>
    <SearchConditions />
    <br>
    <SearchDatasetHooter v-on:search-clicked="onSearchClick" />
    <br>
    <DatasetRecords ref="table" />
    <br>
    <br>

  </div>
</template>

<script>
import SearchDatasetHeader from '@/components/templates/searchdataset/SearchDatasetHeader.vue';
import DatasetRecords from '@/components/templates/common/DatasetRecords.vue';
import SearchDatasetHooter from '@/components/templates/searchdataset/SearchDatasetHooter.vue';
import SearchConditions from '@/components/templates/searchdataset/SearchConditions.vue';
import { provide, inject, ref } from 'vue';
import useSearchRecord from '@/composable/useSearchRecord';
import { Auth } from 'aws-amplify';

export default {
  components: {
    SearchDatasetHeader,
    SearchConditions,
    DatasetRecords,
    SearchDatasetHooter,
  },
  methods: {
    onSearchClick() {
      useSearchRecord(
        this.start_date,
        this.end_date,
        this.customer,
        this.model,
        this.purpose,
        this.freeword,
        this.id_token,
      )
        .then((result) => {
          console.log('SearchDatasetView.vue: ', result);
          this.$refs.table.updateDataset(result);
        })
        .catch(error => {
          if (error.response.status == 401) {
            alert("リロードしてください(トークンの有効期限切れ)")
          } else {
            alert(error)
          }
        });
    },
    reeacquireToken() {
      if (!this.username) {
        Auth.currentAuthenticatedUser({
          bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            this.username = user.username

            const { signInUserSession } = user;
            this.id_token = signInUserSession.idToken.jwtToken
            console.log("idToken", this.id_token);
          })
          .catch((err) => console.log(err));
      }
    }
  },
  setup() {
    // 下位コンポーネントとの共有用変数
    const start_date = ref(null)
    const end_date = ref(null)
    const customer = ref(null)
    const model = ref(null)
    const purpose = ref(null)
    const freeword = ref(null)

    provide('start_date', start_date)
    provide('end_date', end_date)
    provide('customer', customer)
    provide('model', model)
    provide('purpose', purpose)
    provide('freeword', freeword)

    // 上位コンポーネントとの共有用変数
    const id_token = inject('id_token')
    const username = inject('username')

    return {
      start_date,
      end_date,
      customer,
      model,
      purpose,
      freeword,
      id_token,
      username,
    }
  },
  created() {
    this.reeacquireToken();
  }
}
</script>