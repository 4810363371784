import { Storage } from 'aws-amplify';

async function useDownloadDataset(files) {

    // console.log('useDownloadDataset:', files)

    return Promise.all(
        Array.from(files).map(
            (file) =>
                new Promise((resolve, reject) => {
                    // console.log('useDownloadDataset:', file)

                    Storage.get(file.key, {
                        download: true,
                        // progressCallback(progress) {
                        //     console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
                        // }
                    })
                        .then(result => {
                            // console.log(result);
                            resolve(result.Body);
                        })
                        .catch(err => {
                            // console.log(err);
                            reject(err);
                        });
                })
        )
    );

}

export default useDownloadDataset;