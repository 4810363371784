<template>
    <a-cascader :options="options" placeholder="Please select" @change="onChange" style="width: 300px" v-model:value="selected_value"/>
    <div v-if="isOther">
        <a-input v-model:value="model" allowClear=true placeholder="自由入力" style="width: 300px" />
    </div>
</template>

<script>
import { defineComponent, inject } from 'vue';
// 向け先と機種のテーブル
const options = [
    {
        value: 'Common', label: '共通開発',
    },
    {
        value: 'HONDA', label: 'HONDA',
        children: [
            { value: 'Common', label: '共通' },
            { value: 'TY3S', label: 'TY3S' },
            { value: '30EY', label: '30EY' },
            { value: '30AY-KJ', label: '30AY-KJ' },
            { value: '33WA', label: '33WA' },
            { value: '36WA', label: '36WA' },
            { value: '3M0X', label: '3M0X' },
            { value: '3YA', label: '3YA' },
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'SUBARU', label: 'SUBARU',
        children: [
            { value: 'Common', label: '共通' },
            { value: 'ES1', label: 'ES1' },
            { value: 'GC7', label: 'GC7' },
            { value: 'RE7', label: 'RE7' },
            { value: 'EP4', label: 'EP4' },
            { value: 'TM3', label: 'TM3' },
            { value: 'CH2', label: 'CH2' },
            { value: 'CH2-R', label: 'CH2-R' },
            { value: 'DA9', label: 'DA9' },
            { value: 'RH4', label: 'RH4' },
            { value: 'DZ1', label: 'DZ1' },
            { value: 'TK5', label: 'TK5' },
            { value: 'NB8(IPMS)', label: 'NB8(IPMS)' },
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'MAZDA', label: 'MAZDA',
        children: [
            { value: 'Common', label: '共通' },
            { value: 'J59C', label: 'J59C' },
            { value: 'J59K', label: 'J59K' },
            { value: 'J20E', label: 'J20E' },
            { value: 'J30A', label: 'J30A' },
            { value: 'J30S', label: 'J30S' },
            { value: 'J30X', label: 'J30X' },
            { value: 'J30K', label: 'J30K' },
            { value: 'J59G', label: 'J59G' },
            { value: 'J59P', label: 'J59P' },
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'NISSAN', label: 'NISSAN',
        children: [
            { value: 'Common', label: '共通' },
            { value: 'PZ1A', label: 'PZ1A' },
            { value: 'WZ1D', label: 'WZ1D' },
            { value: 'W61QR', label: 'W61QR' },
            { value: 'P42S', label: 'P42S' },
            { value: 'L47T', label: 'L47T' },
            { value: 'PZ1A 2nd', label: 'PZ1A 2nd' },
            { value: 'J32V', label: 'J32V' },
            { value: 'P61QR', label: 'P61QR' },
            { value: 'P33A', label: 'P33A' },
            { value: 'PZ1D', label: 'PZ1D' },
            { value: 'L21C', label: 'L21C' },
            { value: 'L42T', label: 'L42T' },
            { value: 'WZ1E', label: 'WZ1E' },
            { value: 'J42U', label: 'J42U' },
            { value: 'NES', label: 'NES' },
            { value: 'NEX', label: 'NEX' },
            { value: 'IES', label: 'IES' },
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'MMC', label: 'MMC',
        children: [
            { value: 'Common', label: '共通' },
            { value: '5F00', label: '5F00' },
            { value: '5A00', label: '5A00' },
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'SUZUKI', label: 'SUZUKI',
        children: [
            { value: 'Common', label: '共通' },
            { value: 'YEA/YEB', label: 'YEA/YEB' },
            { value: 'YFA', label: 'YFA' },
            { value: 'YAA', label: 'YAA' },
            { value: 'YY8', label: 'YY8' },
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'DAIHATSU', label: 'DAIHATSU',
        children: [
            { value: 'Common', label: '共通' },
            { value: '25M_Senkai', label: '25M_Senkai' },
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'CommercialVehicle', label: '商用車',
        children: [
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'Agriculture-BuildMachine', label: '農建機',
        children: [
            { value: 'Other', label: 'Other' },
        ],
    },
    {
        value: 'Other', label: 'Other',
        children: [
            { value: 'Other', label: 'Other' },
        ],
    },
];

export default defineComponent({
    setup() {
        // 上位コンポーネントから共有された変数
        const customer = inject('customer')
        const model = inject('model')
        return {
            options,
            customer,
            model,
        };
    },
    data() {
        return {
            isOther: false,     // 機能で「Other」を選択しているか
            selected_value: []
        };
    },
    methods: {
        onChange(value) {
            if (value) {
                this.customer = value[0];

                if (value[1]) {
                    if (value[1].match('Other')) {
                        this.model = '';
                        this.isOther = true;
                    }
                    else {
                        this.model = value[1];
                        this.isOther = false;
                    }
                }
                else {
                    this.model = '';
                    this.isOther = true;
                }
            }
            else {
                this.customer = '';
                this.model = '';
                this.isOther = false;
            }
        },
        clear() {
            this.customer = '';
            this.model = '';
            this.selected_value = '';
            this.isOther = false;
        },
    },
}
);

</script>