import axios from 'axios'

async function useSearchRecord(start_date, end_date, customer, model, purpose, freeword, id_token) {

    // console.log('useSearchRecord:', start_date, ',', end_date, ',', customer, ',', model, ',', purpose, ',', freeword)

    var url = 'https://eh2u2ctwtb.execute-api.ap-northeast-1.amazonaws.com/dev/'
        + 'items'
    var params = ''

    // 検索条件の指定があればAPI Gatewayのパラメータに設定する
    // 複数個の条件が指定されている場合は'&'で連結する

    if (start_date) {
        params = params + 'start_date=' + start_date
    }
    if (end_date) {
        if (params) {
            params = params + '&'
        }
        params = params + 'end_date=' + end_date
    }
    if (customer) {
        if (params) {
            params = params + '&'
        }
        params = params + 'customer=' + customer
    }
    if (model) {
        if (params) {
            params = params + '&'
        }
        params = params + 'model=' + model
    }
    if (purpose) {
        if (params) {
            params = params + '&'
        }
        params = params + 'purpose=' + purpose
    }
    if (freeword) {
        if (params) {
            params = params + '&'
        }
        params = params + 'freeword=' + freeword
    }

    if (params) {
        url = url + '?' + params
    }
    // console.log('useSearchRecord:', url)

    var result =''
    await axios.get(url, {headers : {Authorization : id_token}})
    .then(response => {
        // console.log(response.data);
        result = response.data;
    })

    return result;
}

export default useSearchRecord;