<template>
  <a-layout>
    <a-layout-header style="background: #fff">
      <ViewDatasetHeader />
    </a-layout-header>
    <a-layout>
      <a-layout-sider style="background: #fff" :width="300">
        <FolderStructureMenu v-on:menu-changed="onMenuChanged" />
      </a-layout-sider>
      <a-layout-content style="background: #fff">
        <DatasetRecords ref="table" />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { inject } from 'vue';
import ViewDatasetHeader from '@/components/templates/viewdataset/ViewDatasetHeader.vue';
import FolderStructureMenu from '@/components/templates/viewdataset/FolderStructureMenu.vue';
import DatasetRecords from '@/components/templates/common/DatasetRecords.vue';
import useSearchRecord from '@/composable/useSearchRecord'

export default {
  setup() {
    const id_token = inject('id_token')

    return {
      id_token,
    }
  },
  components: {
    ViewDatasetHeader,
    FolderStructureMenu,
    DatasetRecords,
  },
  methods: {
    onMenuChanged(type, value) {
      if (type.match('purpose')) {
        useSearchRecord(
          null,
          null,
          null,
          null,
          value,
          null,
          this.id_token,
        )
          .then((result) => {
            //console.log('SearchDatasetView.vue: ', result);
            this.$refs.table.updateDataset(result);
          })
          .catch(error => {
            if (error.response.status == 401) {
              alert("リロードしてください(トークンの有効期限切れ)")
            } else {
              alert(error)
            }
          });
      }
      else if (type.match('customer')) {
        useSearchRecord(
          null,
          null,
          value,
          null,
          null,
          null,
          this.id_token,
        )
          .then((result) => {
            // console.log('SearchDatasetView.vue: ', result);
            this.$refs.table.updateDataset(result);
          })
          .catch(error => {
            if (error.response.status == 401) {
              alert("リロードしてください(トークンの有効期限切れ)")
            } else {
              alert(error)
            }
          });
      }
      else {
        useSearchRecord(
          null,
          null,
          null,
          null,
          null,
          null,
          this.id_token,
        )
          .then((result) => {
            // console.log('SearchDatasetView.vue: ', result);
            this.$refs.table.updateDataset(result);
          })
          .catch(error => {
            if (error.response.status == 401) {
              alert("リロードしてください(トークンの有効期限切れ)")
            } else {
              alert(error)
            }
          });
      }
    },
  }
}
</script>