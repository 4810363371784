import { Storage } from 'aws-amplify';

async function useListS3Files(s3_path) {

    // console.log('useListS3Files:', s3_path)
 
    var result = ''
    await Storage.list(s3_path + '/') // for listing ALL files without prefix, pass '' instead
        .then((results) => {
            console.log(results.results)
            result = results.results;
        })
        .catch((err) => {
            console.log(err)
            result = err;
        });

    return result;
}

export default useListS3Files;