import axios from 'axios'

async function useInsertRecord(start_date, customer, model, purpose, old_path, upload_to, title, uploader, id_token, ) {

    // console.log('useInsertRecord:', start_date, ',', model, ',', purpose, ',', old_path, ',', upload_to, ',', uploader)

    var url = 'https://keghti5d5k.execute-api.ap-northeast-1.amazonaws.com/dev/'
        + 'items?'
        + 'start_date=' + start_date + '&'
        + 'customer=' + customer + '&'
        + 'model=' + model + '&'
        + 'purpose=' + purpose + '&'
        + 's3_path=' + upload_to + '/' + title + '&'
        + 'uploader=' + uploader

    if (old_path) {
        url += '&old_path=' + old_path
    }

    console.log('useInsertRecord:', url)
    
    //送信処理
    var result = ''
    await axios.get(url,  {headers : {Authorization : id_token}})
        .then(response => {
            console.log(response);
            if (response?.response?.status === 401) {
                throw new Error(response);
            }
            result = response.data;
        });
    return result;
}

export default useInsertRecord;